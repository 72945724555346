/***************************************************
██╗    ██╗██╗██████╗ ██╗ ██████╗ ██████╗ ███╗   ███╗
██║    ██║██║██╔══██╗██║██╔════╝██╔═══██╗████╗ ████║
██║ █╗ ██║██║██████╔╝██║██║     ██║   ██║██╔████╔██║
██║███╗██║██║██╔══██╗██║██║     ██║   ██║██║╚██╔╝██║
╚███╔███╔╝██║██████╔╝██║╚██████╗╚██████╔╝██║ ╚═╝ ██║
 ╚══╝╚══╝ ╚═╝╚═════╝ ╚═╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝
***************************************************/
let styles = [
    'background: black'
    , 'color: white'
    , 'display: block'

    , 'line-height: 40px'
    , 'text-align: center'
    , 'fontsize: 20px'
    , 'font-weight: lighter'
].join(';');

console.info('%c HANDMADED WITH ❤ BY WIBICOM ', styles);

/****************************************************
*****************************************************
*GESTION DU PUBLIC PATH DYNAMIC ;
***************************************************** 
****************************************************/
__webpack_public_path__ = data.base + '/assets/';
/****************************************************
*****************************************************
*IMPORT GLOBALS DEPENDENCIES FROM NODE MODULES;
*****************************************************
****************************************************/
window.isTouchDevice = false
window.dev = false;
import $ from 'jquery';
window.$ = window.jQuery = $;
import './views/analytics';
window.analytics.init();
import './init.js';
let DEBUG = process.env.NODE_ENV == 'development' ? true : false;
if (!DEBUG) {

    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () { };
    }
}
