
window.initGlobal = function() {
    if (window.navigator.userAgent.match(/MSIE|Trident/) !== null){
        $('body').addClass('is-ie');
    }
    if (window.isTouchDevice === true) {
        $('body').addClass('is-touch').removeClass('is-desktop');
        try { // prevent exception on browsers not supporting DOM styleSheets properly
            for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;

                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;

                    if (styleSheet.rules[ri].selectorText.match(':hover')) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        } catch (ex) {}
    }else{
        $('body').addClass('is-desktop').removeClass('is-touch');
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--dynamic-vh', `${vh}px`);

    let resizeTimout = false
    let windowWidth = window.innerWidth
    
    window.appEvent.resize(() => {
        let dynamicVh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--dynamic-vh', `${dynamicVh}px`);
        let header = document.querySelector('header');
        document.documentElement.style.setProperty('--header-height', "auto");
        document.documentElement.style.setProperty('--header-height', `${header.scrollHeight}px`);
        
        if (window.innerWidth != windowWidth) {
            windowWidth = window.innerWidth
            window.menuNav.menuResizeHandler()
        }
        
        
        
        
        
    }, true)
   
    appEvent.listen('app:launcEnterAnimation',()=>{
        $('.header-home-spacer .header-border').removeClass('bg-active')
        $('header').removeClass('show-logo')
        window.scrollAnimation.initEnterAnimation()
    })
    appEvent.listen('app:threejsAnimation:start',()=>{
        window.scrollAnimation.lazyload()
        window.scrollAnimation.initView()

    });

    
 
   
    

};

window.initView = function() {
    window.menuNav.updateCurrentLink();
};

$(window).on('load', function(){
    
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    let header = document.querySelector('header');
    document.documentElement.style.setProperty('--header-height', "auto");
    document.documentElement.style.setProperty('--header-height', `${header.scrollHeight}px`);
    
    
    const barbaJsPromise = new Promise((resolve)=>{
        import(/*webpackMode:"lazy"*/ /*eagger*/ /* webpackChunkName: "barbaSysthem" */ "./_barba/barba.js").then(({default: BarbaJs})=>{
            console.log(BarbaJs)
            window.barba = new BarbaJs();
            window.barba.setDefaultTransition();
            resolve(true); 
  
        })
        
    })
    
    const ScrollAnimationPromise = new Promise((resolve)=>{
        import(/*webpackMode:"lazy"*/ /*eagger*/ /* webpackChunkName: "scrollComponentSysthem" */ "./views/scrollAnimation").then(()=>{
          
            resolve(true); 
  
        })
        
    })
    const ScrollComponentPromise = new Promise((resolve)=>{
        import(/*webpackMode:"lazy"*/ /*eagger*/ /* webpackChunkName: "scrollComponentSysthem" */ "./_barba/components/ScrollComponent").then(({default:ScrollComponent})=>{
            window.globalScroll = new ScrollComponent({
                smooth:true,
                offset:'header'
            });
            
            
            resolve(true); 
  
        })
        
    })
    const MenuNavPromise = new Promise((resolve)=>{
        import(/*webpackMode:"lazy"*/ /*eagger*/ /* webpackChunkName: "scrollComponentSysthem" */ "./views/menuNav.js").then(({default:ScrollComponent})=>{
            window.menuNav.init();
            resolve(true); 
  
        })
        
    })
    Promise.all([barbaJsPromise, ScrollComponentPromise,ScrollAnimationPromise,MenuNavPromise]).then((values) => {
        window.globalScroll.init().then(()=>{
            window.scrollAnimation.init()
            window.scrollAnimation.initGlobal()
            window.scrollAnimation.lazyload()
            window.scrollAnimation.initView()
            window.barba.init();
            window.landingLoader.init();
        })

    });
   
   
   
});
